<div class="forgot-password-screen">
  <div class="right-part"></div>
  <div class="d-flex flex-column justify-content-center h-100 flex-wrap">
    <div class="col-lg-5 col-12 m-auto d-table">
      <div class="px-3">
        <div class="mb-5 mt-3 mt-lg-0">
          <img src="../../../assets/img/sidebar-logo.svg" class="m-auto d-table logo" alt="">
        </div>
        <h4>{{LabelConstants.ForgotPassword}}</h4>
        <form [formGroup]="EmailForm" class="form" (ngSubmit)="submitForgotPassForm()">
          <div class="form-group">
            <label>{{LabelConstants.Email}}<span class="text-danger">*</span> </label>
            <div class="input-group mb-1">
              <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
              <input type="Email" oninput="this.value = this.value.replace(/^\s+/g, '')" oninput="this.value = this.value.toLowerCase()" formControlName="UserEmail" placeholder="Enter your email" class="form-control" maxlength="100" />
            </div>
              <span class="text-danger" *ngIf="submitted && EmailFormValidations['UserEmail'].errors?.['required']">{{
                ErrorMessages.EmalRequired }}</span>
              <span class="text-danger" *ngIf="submitted && EmailFormValidations['UserEmail'].errors?.['pattern']">{{
                  ErrorMessages.EmailNotValid }}</span>
          </div>
          <div class="col-lg-12 col-12">
            <div class="mb-3">
              <mat-label> Role<span>*</span></mat-label>
              <mat-form-field class="w-100">
                <mat-select formControlName="Role" placeholder="Select Role">
                  <mat-option *ngFor="let role of roleList" [value]="role.roleManagementId">
                    {{role.roleName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>                  
              <span class="text-danger" *ngIf="submitted && EmailFormValidations['Role'].errors?.['required']">
                Please select role.
              </span>
            </div>
          </div>
          <div class="form-group">
            <button class="btn login-btn mt-3 mb-3" type="submit">{{LabelConstants.SendLink}} <i
                class="fa-solid fa-arrow-right ms-1"></i></button>
          </div>
          <div class="form-group d-flex align-items-center justify-content-center">
            <a class="mt-3 mb-3 text-center d-block" type="submit" routerLink="/"> <i
              class="fa-solid fa-arrow-left me-2"></i>{{LabelConstants.BackToLogin}}</a>
          </div>
          <input type="hidden" class="hide" name="token" id="token" value="" />
        </form>
      </div>
    </div>
  </div>
</div>
