import { Component } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  ngAfterViewInit() {
    //We loading the player script on after view is loaded
    import('../../../assets/script/sidebar-script.js');
  }
}
