import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { commonLabelConstants } from 'src/app/constants/LabelConstants';

@Component({
  selector: 'app-delete-role-confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  action: string;
  ImageSrc:any;
  LabelConstants:any;
  heading : string;
  date : any;
  isSession : any = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { action: string, heading : string, date : any, isSession : any }) {
    this.action = data.action;
    this.heading = data.heading;
    this.date = data.date;
    this.isSession = data.isSession;
  }
  
  ngOnInit(): void {
    this.LabelConstants = commonLabelConstants
  }
}
