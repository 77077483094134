export const AppoinmentErrorConstants = {
  AppoinmentDateRequired: 'Appointment date is required.',
  AppoinmentTimeRequired: 'Appointment time is required.',
  AppoinmnetTypeRequired: 'Appointment type is required.',
  AmountRequired: 'Amount is required.',
  AddressRequired: 'Address is required.',
  CountryRequired: 'Country is required.',
  CityRequired: 'Town/City  is required.',
  LinkRequired: 'Link is required.',
  LinkNotValid: 'Link is not valid.',
  MessageRequired: 'Message is required.',
  StateRequired: 'Address line 2 is required.',
  ZipCodeRequired: 'Zip Code is required.',
  ZipCodeNotValid: 'Zip Code is invalid.'
}

export const CommonErrorMessages = {
  ConfirmPasswordRequired: 'Confirm password is required.',
  EmailNotValid: 'Email is not valid.',
  EmalRequired: 'Email is required.',
  FirstNameRequired: 'First name is required.',
  LastNameIsRequired: 'Last name is required.',
  NewPasswordRequired: 'New password is required.',
  OldPasswordRequired: 'Old password is required.',
  PasswordRequired: 'Password is required.',
  PasswordsDoNoMatch: "New password and confirm password does not matched.",
  PasswordValidations: 'Password requires min 8 char, max 16 char, one upper case, one lower case, one special character, one number and without any space.',
};

export const CouponErrorMessages = {
  CouponCode: 'Coupon code is required.',
  CouponCodeDateInvalid: 'End date should be greater than start date.',
  CouponDiscountTypeRequired: 'Discount type is required.',
  CouponEndDateRequired: ' End date is required.',
  CouponPriceRequired: 'Price is required.',
  CouponPercentageRequired: 'Percentage is required.',
  CouponStartDateRequired: ' Start date is required.',
  CouponTypeRequired: 'Coupon type is required.',
  PriceValid: "Price should be greater than zero.",
  PercentageValid: "Percentage should be greater than zero."
};

export const CustomerErrorMessages = {
  AddressRequired: 'Address is required.',
  CityRequired: 'Town/City  is required.',
  CountryRequired: 'Country is required.',
  EmailNotValid: 'Email is not valid.',
  EmalRequired: 'Email is required.',
  FirstNameRequired: 'First Name is required.',
  LastNameIsRequired: 'Last Name is required.',
  PhoneNoRequired: 'Phone number is required.',
  PhoneNumberNotValid: 'Mobile number is not valid.',
  PostcodeRequired: 'Postal code is required.',
  PostalNotValid: 'Postal code is not valid.',
  StateRequired: 'Address line 2 is required.',
};

export const RoleErrorMessages = {
  RoleNameRequired: 'Role name is required.',
};

export const UserErrorMessages = {
  AddressRequired: 'Address line 1 is required.',
  CityRequired: 'Town/City is required.',
  CountryRequired: ' Country is required.',
  EmailNotValid: 'Email is not valid.',
  EmalRequired: 'Email is required.',
  FirstNameRequired: 'First name is required.',
  LastNameIsRequired: 'Last name is required.',
  PhoneNoRequired: 'Phone number is required.',
  PhoneNumberNotValid: 'Phone number is not valid.',
  PostcodeRequired: 'Postal Code is required.',
  PostalNotValid: 'Postal code is not valid.',
  RoleNameRequired: 'Role Name is required.',
  StateRequired: 'Address line 2 is required.',
};

export const QuestionErrorMessages = {
  OneOptionRequired: 'At least one option is required.',
  OptionFieldRequired: 'Option field is required.',
  QuestionTextRequired: 'Question text is required',
  QuestionTypeRequired: 'Question type is required.',
  MandetoryQuestions: 'You must add at least two options.',
  optionAllowed: 'Only 4 Options Allowed',
  optionMandotory: 'Two options are mandatory!, you can not delete this option',
};

export const PassErrorMessages = {
  AmountRequired: 'Amount is required.',
  ExpireDaysRequired: 'Expiry days is required.',
  NoOfPassRequired: 'No of sessions is required.',
  PassNameRequired: 'Pass name is required.',
  PassTextRequired: 'Pass description is required.',
  PassTypeRequired: 'Pass type is required.',
  AmountNotZero: "Amount should be greater than zero.",
  NumberOfPassNotZero: "Number of passes should be greater than zero.",
  ExpiryDayNotZero: "Expiry days should be greater than zero."
};

export const EventErrorMessages = {
  AddressRequired: 'Address is required.',
  CityRequired: 'Town/City  is required.',
  ContactNORequired: 'Contact is required.',
  CountryRequired: 'Country is required.',
  EventEndTimeRequired: 'End time is required.',
  EventFeesRequired: 'Fees is required.',
  EventDesRequired: 'Event description is required.',
  EventHostRequired: 'Hosted by is required.',
  EventNameRequired: 'Event name is required.',
  EventStartEndDateRequired: 'Start date and end date is required.',
  EventStartTimeRequired: 'Start time is required.',
  ParticipantRequired: 'Participants count is required.',
  StateRequired: 'Address line 2 is required.',
  ZipCodeRequired: 'Zip Code is required.',
  InvalidConatctNumber: "Contact number is not valid.",
  TimeValidation: " End time must not be greater than or equals to start time.",
  InvalidZipCode: "Zip code is not valid."
};

export const ClassErrorMessages = {
  ClassNameRequired: "Class name is required.",
  ZoomLinkRequired: "Link is required.",
  ZoomLinkInvalid: "Link is invalid.",
  SessionDateRequired: "Session date is required.",
  SessionStartDateRequired: "Session start date is required.",
  SessionEndDateRequired: "Session end date is required.",
  ParticipantLimitRequired: "Participant limit is required.",
  ClassDescriptionRequired: "Class description is required.",
  SlotStartTimeRequired: "Slot start time is required.",
  SlotEndTimeRequired: "Slot end time is required.",
  HostedByRequired: "Hosted by is required.",
}

export const ResetPassErrorConstants = {
  ConfPassRequired: 'Confirm password is required.',
  NewPassInvalid: 'Password requires min 8 char, max 16 char, one upper case, one lower case, one special character, one number and without any space.',
  NewPassRequired: 'New password is required.',
  PassNotMatch: ' New password and confirm password does not matched.',
};

export const SettingsErrorConstants = {
  ImageAlingnmentRequired: 'Image aligment is required.',
  ContentTitleRequired: 'Content title is required.',
  ContentImageRequired: 'Content image is required.',
  ContentRequired: 'Content is required.',
  PersonNameRequired: 'Person name is required.'
}

export const ReportErrorMessages = {
  pleaseWriteMessage: "Please write message",
  qtyGraterValidation: "Quantity can not be greater than requested quantity.",
  pleaseEnterQty: "Please enter quantity.",
  pleaseEnterAmount: "Please enter amount.",
  validQty: "Please enter valid quantity.",
  amountCanNotGraterThanRefundableAmount: "Amount can not be more than refundable amount"
}

export const AddNewSessionErrorMessage = {
  replyIsRequired: "Reply is required."
}

