<div class="layout has-sidebar fixed-sidebar">
  <app-menu-sidebar></app-menu-sidebar>
  <div class="layout">
    <header class="header row g-0">
      <app-header></app-header>
    </header>
    <main class="content">
      <router-outlet></router-outlet>
    </main>
    <div class="overlay"></div>
  </div>
</div>
